import React from "react";
import aboutus from "../assets/aboutus.jpeg";

const AboutUs = () => {
  return (
    <div className="p-8 scroll-m-16 mt-0" id="about-us">
      <h2 className="text-4xl font-bold mb-8 text-center text-[#1e296c] hover:text-[#999] transition duration-300">
        About Us
      </h2>
      <div className="pt-4 mt-10 flex flex-col md:flex-row">
        {/* Text Section */}
        <div className="w-full md:w-[60%] mb-6 md:mb-0">
          <p className="mb-4">
            <b>Welcome to Cloud Square!</b> As a Power Platform expert, We
            specialize in empowering businesses to achieve their digital
            transformation goals by harnessing the full potential of Microsoft's
            Power Platform. With extensive experience in SharePoint, Power
            Automate, Power Apps, and SharePoint Framework (SPFx) with React, We
            provide tailored solutions that streamline processes, enhance
            collaboration, and drive efficiency across your organization.
          </p>
          <p className="mb-4">
            From automating workflows with Power Automate to developing custom
            apps with Power Apps and enhancing SharePoint experiences using SPFx
            and React, our goal is to deliver innovative and reliable solutions
            that meet your unique business needs. Whether you're looking to
            improve productivity, simplify complex tasks, or modernize your
            SharePoint environment, We're here to help you every step of the
            way.
          </p>
          <p>
            Let's work together to transform your ideas into powerful digital
            solutions!
          </p>
        </div>

        {/* Image Section */}
        <div className="w-full md:w-[40%] flex justify-center md:justify-end">
          <img
            alt="aboutus"
            src={aboutus}
            className="w-full h-[300px] max-w-xs md:max-w-sm lg:max-w-md rounded-lg object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
