import React from "react";

const Footer = () => {
  return (
    <div className="py-4 text-center bg-slate-50">
      Copyright © 2024 Cloud Square - All Rights Reserved.
    </div>
  );
};

export default Footer;
