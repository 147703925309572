// src/OurServices.js

import React from "react";
import sharepointLogo from "../assets/sharepointIcon.svg";
import powerAutomateLogo from "../assets/PowerAutomate_scalable.svg";
import powerAppsLogo from "../assets/PowerApps_scalable.svg";
import consultingLogo from "../assets/consulting.png";
import trainingLogo from "../assets/analysis.png";
import integrationLogo from "../assets/product-development.webp";

const OurServices = () => {
  return (
    <div className="p-8 mt-16 scroll-m-16" id="our-services">
      <h2 className="text-4xl font-bold mb-8 text-center text-[#1e296c] hover:text-[#999] transition duration-300">
        Our Services
      </h2>
      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {/* Service 1 */}
        <div className="p-6 border rounded-lg shadow-lg hover:shadow-xl transition duration-300 flex flex-col md:flex-row items-center h-auto">
          <div className="flex items-center justify-center w-full md:w-1/3 mb-4 md:mb-0">
            <div className="w-24 h-24 rounded-full overflow-hidden  flex items-center justify-center">
              <img
                src={sharepointLogo}
                alt="SharePoint Logo"
                className="w-full h-full object-cover p-5"
              />
            </div>
          </div>
          <div className="flex-grow">
            <h3 className="text-2xl font-semibold mb-4 text-[#1e296c] hover:text-[#999] transition duration-300">
              SharePoint Development
            </h3>
            <p>
              Build, customize, and manage your SharePoint environment,
              including creating modern web parts with SPFx and React, to
              enhance collaboration and information sharing across your
              organization.
            </p>
          </div>
        </div>

        {/* Service 2 */}
        <div className="p-6 border rounded-lg shadow-lg hover:shadow-xl transition duration-300 flex flex-col md:flex-row items-center h-auto">
          <div className="flex items-center justify-center w-full md:w-1/3 mb-4 md:mb-0">
            <div className="w-24 h-24 rounded-full overflow-hidden  flex items-center justify-center">
              <img
                src={powerAutomateLogo}
                alt="Power Automate Logo"
                className="w-full h-full object-cover p-5"
              />
            </div>
          </div>
          <div className="flex-grow">
            <h3 className="text-2xl font-semibold mb-4 text-[#1e296c] hover:text-[#999] transition duration-300">
              Power Automate Solutions
            </h3>
            <p>
              Automate repetitive tasks and complex workflows with Power
              Automate to improve productivity and reduce manual effort.
            </p>
          </div>
        </div>

        {/* Service 3 */}
        <div className="p-6 border rounded-lg shadow-lg hover:shadow-xl transition duration-300 flex flex-col md:flex-row items-center h-auto">
          <div className="flex items-center justify-center w-full md:w-1/3 mb-4 md:mb-0">
            <div className="w-24 h-24 rounded-full overflow-hidden  flex items-center justify-center">
              <img
                src={powerAppsLogo}
                alt="Power Apps Logo"
                className="w-full h-full object-cover p-5"
              />
            </div>
          </div>
          <div className="flex-grow">
            <h3 className="text-2xl font-semibold mb-4 text-[#1e296c] hover:text-[#999] transition duration-300">
              Power Apps Development
            </h3>
            <p>
              Develop custom applications with Power Apps that suit your
              business needs, enabling a mobile-first experience for your users.
            </p>
          </div>
        </div>

        {/* Service 4 */}
        <div className="p-6 border rounded-lg shadow-lg hover:shadow-xl transition duration-300 flex flex-col md:flex-row items-center h-auto">
          <div className="flex items-center justify-center w-full md:w-1/3 mb-4 md:mb-0">
            <div className="w-24 h-24 rounded-full overflow-hidden  flex items-center justify-center">
              <img
                src={consultingLogo}
                alt="Consulting Logo"
                className="w-full h-full object-cover p-5"
              />
            </div>
          </div>
          <div className="flex-grow">
            <h3 className="text-2xl font-semibold mb-4 text-[#1e296c] hover:text-[#999] transition duration-300">
              Consulting & Support
            </h3>
            <p>
              Provide expert consulting and ongoing support for your Power
              Platform and SharePoint solutions, ensuring optimal performance.
            </p>
          </div>
        </div>

        {/* Service 5 */}
        <div className="p-6 border rounded-lg shadow-lg hover:shadow-xl transition duration-300 flex flex-col md:flex-row items-center h-auto">
          <div className="flex items-center justify-center w-full md:w-1/3 mb-4 md:mb-0">
            <div className="w-24 h-24 rounded-full overflow-hidden  flex items-center justify-center">
              <img
                src={trainingLogo}
                alt="Training Logo"
                className="w-full h-full object-cover p-5"
              />
            </div>
          </div>
          <div className="flex-grow">
            <h3 className="text-2xl font-semibold mb-4 text-[#1e296c] hover:text-[#999] transition duration-300">
              Training & Workshops
            </h3>
            <p>
              Conduct training sessions and workshops to help your team make the
              most of Microsoft's Power Platform and SharePoint capabilities.
            </p>
          </div>
        </div>

        {/* Service 6 */}
        <div className="p-6 border rounded-lg shadow-lg hover:shadow-xl transition duration-300 flex flex-col md:flex-row items-center h-auto">
          <div className="flex items-center justify-center w-full md:w-1/3 mb-4 md:mb-0">
            <div className="w-24 h-24 rounded-full overflow-hidden  flex items-center justify-center">
              <img
                src={integrationLogo}
                alt="Custom Integrations Logo"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="flex-grow">
            <h3 className="text-2xl font-semibold mb-4 text-[#1e296c] hover:text-[#999] transition duration-300">
              Custom Integrations
            </h3>
            <p>
              Develop custom integrations with third-party applications and
              services, extending the capabilities of your Power Platform and
              SharePoint solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
