import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    contactNumber: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_enjtft3", // Replace with your service ID
        "template_njsfpbb", // Replace with your template ID
        formData,
        "Co7cT17CKj5W4SwG6" // Replace with your user ID
      )
      .then((response) => {
        console.log("Message sent successfully:", response);
        setStatus("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          subject: "",
          contactNumber: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        setStatus("Failed to send message.");
      });
  };

  return (
    <div className="p-8 mt-12 max-w-6xl mx-auto scroll-m-16" id="contact-us">
      <h2 className="text-4xl font-bold mb-16 text-center text-[#1e296c] hover:text-[#999] transition duration-300">
        Contact Us
      </h2>
      <div className=" flex flex-wrap">
        {/* Contact Information Section */}
        <div className="w-full md:w-1/2 pr-16 mb-8 md:mb-0">
          <div className="space-y-6">
            {/* Person 1 Address */}
            <div className="bg-white p-6 border h-40 border-gray-300 rounded-lg shadow-md">
              {/* <h3 className="text-2xl font-semibold mb-4 text-[#1e296c]">
                Vijay Prajapati
              </h3> */}
              <p className="flex items-center mb-2">
                <EmailOutlinedIcon className="mr-2 text-[#1e296c]" />
                <a
                  href="mailto:info@cloudsquare.digital"
                  className="text-blue-600 hover:underline"
                >
                  info@cloudsquare.digital
                </a>
              </p>
              {/* <p className="flex items-center mb-2">
                <PhoneIcon className="mr-2 text-[#1e296c]" />
                <a
                  href="tel:+919724204066"
                  className="text-blue-600 hover:underline"
                >
                  +919724204066
                </a>
              </p> */}
              <p className="flex items-center">
                <BusinessIcon className="mr-2 text-[#1e296c]" />
                Ozone Residency, New Ranip, Ahmedabad, Ranip, Gujarat
                382470
              </p>
            </div>

            {/* Person 2 Address */}
            {/* <div className="bg-white p-6  h-40 border border-gray-300 rounded-lg shadow-md"> */}
              {/* <h3 className="text-2xl font-semibold mb-4 text-[#1e296c]">
                Vijay Prajapati
              </h3> */}
              {/* <p className="flex items-center mb-2">
                <EmailOutlinedIcon className="mr-2 text-[#1e296c]" />
                <a
                  href="mailto:info@cloudsquare.digital"
                  className="text-blue-600 hover:underline"
                >
                  info@cloudsquare.digital
                </a>
              </p> */}
              {/* <p className="flex items-center mb-2">
                <PhoneIcon className="mr-2 text-[#1e296c]" />
                <a
                  href="tel:+919724204066"
                  className="text-blue-600 hover:underline"
                >
                  +919724204066
                </a>
              </p> */}
              {/* <p className="flex items-center">
                <BusinessIcon className="mr-2 text-[#1e296c]" />
                C-404, Ozone Residency, New Ranip, Ahmedabad, Ranip, Gujarat
                382470
              </p> */}
            {/* </div> */}
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="w-full md:w-1/2 px-4">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Name and Subject Fields */}
            <div className="flex flex-wrap mb-6">
              <div className="flex flex-col w-full md:w-1/2 mb-4 md:mb-0 pr-2">
                <label
                  htmlFor="name"
                  className="text-lg font-semibold mb-2 text-[#1e296c]"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col w-full md:w-1/2 pl-2">
                <label
                  htmlFor="subject"
                  className="text-lg font-semibold mb-2 text-[#1e296c]"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* Email and Contact Number Fields */}
            <div className="flex flex-wrap mb-6">
              <div className="flex flex-col w-full md:w-1/2 mb-4 md:mb-0 pr-2">
                <label
                  htmlFor="email"
                  className="text-lg font-semibold mb-2 text-[#1e296c]"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col w-full md:w-1/2 pl-2">
                <label
                  htmlFor="contactNumber"
                  className="text-lg font-semibold mb-2 text-[#1e296c]"
                >
                  Contact Number
                </label>
                <input
                  type="text"
                  id="contactNumber"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  required
                  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* Message Field */}
            <div className="flex flex-col mb-6">
              <label
                htmlFor="message"
                className="text-lg font-semibold mb-2 text-[#1e296c]"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
                className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full py-3 px-4 bg-blue-700 text-white font-medium rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-300"
            >
              Send Message
            </button>
          </form>
          {status && (
            <p className="mt-4 text-center text-lg font-medium">{status}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
