import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import OurServices from "./OurServices";
import Header from "./Header";
import Footer from "./Footer";
import bannerVid from "../assets/bannervid.mp4";

const Body = () => {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "aboutus",
      element: <AboutUs />,
    },
    {
      path: "contactus",
      element: <ContactUs />,
    },
    {
      path: "services",
      element: <OurServices />,
    },
  ]);

  return (
    <div className="flex flex-col flex-grow">
      <Header />
      <div className="relative w-full h-[700px] overflow-hidden">
        {/* Video Banner */}
        <video
          autoPlay
          loop
          muted
          src={bannerVid}
          type="video/mp4"
          className="absolute w-full h-full object-cover"
        />

        {/* Content Overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          <div className="text-center text-white p-4 max-w-xl">
            <h1 className="text-4xl font-bold mb-4">Welcome to Cloud Square</h1>
            <p className="text-lg mb-6">
              Empowering Your Digital Transformation Journey. Explore our
              innovative solutions and services that drive growth, efficiency,
              and success in the digital age.
            </p>
            <a
              href="/#contact-us"
              className="relative inline-block text-lg font-medium py-3 px-8 rounded-full text-white bg-blue-600 overflow-hidden group transition-all duration-500 ease-in-out"
            >
              <span className="absolute inset-0 bg-sky-500 rounded-full transition-transform duration-500 ease-out group-hover:translate-x-0 translate-x-[-100%]"></span>
              <span className="relative z-10">Get Started</span>
            </a>
          </div>
        </div>
      </div>

      <div className="flex-grow mt-20">
        <RouterProvider router={appRouter} />
      </div>
      <Footer />
    </div>
  );
};

export default Body;
