import React from "react";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import OurServices from "./OurServices";

const Home = () => {
  return (
    <React.Fragment>
      <AboutUs />
      <OurServices />
      <ContactUs />
    </React.Fragment>
  );
};

export default Home;
